<template>
  <AtroCard type="auth" size="md">
    <AtroHeading
      v-if="isDaemonContext"
      semibold
      class="mb-6 text-atro-slate-purple"
      size="lg"
      text="Sign in to connect"
    />
    <AtroHeading
      v-if="view === 'connect'"
      semibold
      class="mb-6 text-atro-gray-1"
      size="xs"
      text="Finish setting up your account"
    />
    <AtroContent
      v-if="showOAuthProviders"
      class="w-full space-y-4"
      direction="col"
    >
      <AtroButton
        v-for="provider in OAUTH_PROVIDERS"
        block
        class="ring-2 ring-atro-warm-gray-1"
        icon-size="lg"
        type="blank"
        :icon-left="provider.iconName"
        :key="provider.name"
        :pending="oAuthProvidersPending[provider.name]"
        :text="`${buttonTextPrefix} ${provider.label}`"
        @click="onOAuthLogin(provider.name)"
      />
    </AtroContent>

    <AtroSpan
      v-if="showOAuthProviders"
      semibold
      class="py-4 text-atro-gray-1"
      text="Or"
    />

    <AtroContent class="w-full">
      <!-- MAGIC LINK SENT CONFIRMATION -->
      <AtroContent
        v-if="showMagicLinkSent"
        class="w-full space-y-2 text-center"
        direction="col"
        items="center"
        justify="center"
      >
        <AtroHeading
          semibold
          class="text-atro-slate-purple"
          text="Magic Link Sent!"
        />
        <AtroParagraph
          :text="`We've sent an email to ${magicLinkFormData.email} that contains a link to login`"
        />
      </AtroContent>

      <!-- MAGIC LINK LOGIN -->
      <Form
        v-if="showMagicLinkLoginForm"
        v-model="magicLinkFormData"
        input-width="full"
        @submit="onMagicLinkRequest"
      >
        <FormKit
          type="email"
          maxlength="50"
          name="email"
          placeholder="Email"
          validation="required|email"
        />
        <AtroContent class="-mt-4 w-full" justify="between">
          <div v-auto-animate>
            <FormKitMessages />
          </div>
          <AtroSpan
            class="cursor-pointer text-xs font-semibold text-primary hover:text-primary-75"
            text="Have a Password? Sign in here."
            @click.stop="loginMode = 'credentials'"
          />
        </AtroContent>

        <template #submit="{ state: { valid } }">
          <AtroButton
            text="Request Magic Link"
            :disabled="!valid"
            :pending="magicLinkPending"
          />
        </template>
      </Form>

      <!-- CREDENTIALS LOGIN -->
      <Form
        v-if="showCredentialsLoginForm"
        v-model="loginFormData"
        input-width="full"
        @submit="onCredentialLogin"
      >
        <FormKit
          type="email"
          maxlength="50"
          name="email"
          placeholder="Email"
          validation="required|email"
        />
        <FormKit
          type="password"
          maxlength="32"
          name="password"
          placeholder="Password"
          validation="required"
        />

        <AtroContent class="-mt-4 w-full" justify="between">
          <div v-auto-animate>
            <FormKitMessages />
          </div>
          <AtroSpan
            class="cursor-pointer text-sm text-primary hover:text-primary-75"
            text="Forgot Password?"
            @click.stop="onForgotPassword"
          />
        </AtroContent>

        <template #submit="{ state: { valid } }">
          <AtroButton :pending text="Sign in with email" :disabled="!valid" />
        </template>
      </Form>

      <!-- SIGN UP -->
      <Form
        v-else-if="showCredentialsSignupForm"
        v-model="signupFormData"
        input-width="full"
        @submit="onCredentialSignup"
      >
        <FormKit
          type="email"
          maxlength="50"
          name="email"
          placeholder="Email"
          validation="required|email|length:5,50"
          :disabled="!!claimToken"
        />
        <FormKit
          type="password"
          maxlength="32"
          name="password"
          placeholder="Password"
          validation="required|length:12,32"
        />
        <FormKit
          type="password"
          maxlength="32"
          name="passwordConfirmation"
          validation-label="Password"
          placeholder="Password Confirmation"
          validation="required|confirm:password|length:12,32"
        />

        <template #submit="{ state: { valid } }">
          <AtroButton :pending :disabled="!valid" :text="credentialsFormCTA" />
        </template>
      </Form>
    </AtroContent>

    <template v-if="!showMagicLinkSent">
      <AtroSpan class="mt-4 !text-atro-gray-1" size="xs">
        {{ authTermsText }}
        <NuxtLink class="underline" to="https://atro.com/terms" target="_blank"
          >Terms</NuxtLink
        >
      </AtroSpan>

      <NuxtLink v-if="showSignupCta" :to="toggleLinkto">
        <AtroButton class="mt-2" type="transparent" :text="toggleViewText" />
      </NuxtLink>
    </template>
  </AtroCard>
</template>

<script lang="ts">
import type { FormKitNode } from '@formkit/core'
import { FormKitMessages } from '@formkit/vue'

type OauthProvider = {
  name: string
  label: string
  iconName: IconName
}

const OAUTH_PROVIDERS: OauthProvider[] = [
  {
    name: 'google',
    label: 'Google',
    iconName: 'google',
  },
  {
    name: 'azure-ad',
    label: 'Microsoft',
    iconName: 'office-365',
  },
]
</script>

<script setup lang="ts">
export interface Props {
  view: 'connect' | 'login' | 'signup'
}

const { view = 'login' } = defineProps<Props>()

useRecaptchaProvider()
const route = useRoute()
const toast = useToast()
const { data: session, signIn } = useAuth()
const { pending, wrapWithPending } = usePendingWrap()
const { isDaemonContext, isWebContext } = useClientContext()
const { execute: getRecaptchaToken } = useChallengeV3('register')

const claimToken = route.query['claim-token'] || session.value?.claimToken
const inviteToken = route.query['invite-token']
const magicToken = route.query['magic-token']

const oAuthProvidersPending = ref(
  OAUTH_PROVIDERS.reduce(
    (obj, provider) => {
      obj[provider.name] = false
      return obj
    },
    {} as { [key: string]: any },
  ),
)
const initializedEmail =
  (route.query.email as string) || session.value?.user?.email || ''
const loginFormData = ref({ email: initializedEmail, password: '' })
const loginMode = ref<'magic' | 'credentials'>('magic')
const magicLinkFormData = ref({ email: initializedEmail })
const magicLinkSent = ref(false)
const signupFormData = ref({
  email: initializedEmail,
  password: '',
  passwordConfirmation: '',
})

const afterAuthRedirectTo = computed(() => {
  if (route.query.callbackUrl)
    return new URL(route.query.callbackUrl as string).pathname as string
  else if (route.query.next) {
    return route.query.next as string
  } else {
    return isWebContext.value ? '/' : '/devices/handoff'
  }
})

const authTermsText = computed(() => {
  if (view === 'login') {
    return "By Signing In, You Accept Atro's"
  } else {
    return "By Signing Up, You Accept Atro's"
  }
})

const buttonTextPrefix = computed(() => {
  if (view === 'connect') {
    return 'Connect'
  } else if (view === 'login') {
    return 'Sign in with'
  } else if (view === 'signup') {
    return 'Sign up with'
  }
})

const credentialsFormCTA = computed(() =>
  view === 'connect' ? 'Continue' : 'Sign up with email',
)
const showCredentialsLoginForm = computed(
  () =>
    view === 'login' &&
    loginMode.value === 'credentials' &&
    !showMagicLinkSent.value,
)
const showCredentialsSignupForm = computed(
  () => ['connect', 'signup'].includes(view) && !showMagicLinkSent.value,
)
const showMagicLinkSent = computed(() => magicLinkSent.value)
const showMagicLinkLoginForm = computed(
  () =>
    view === 'login' && loginMode.value === 'magic' && !showMagicLinkSent.value,
)
const showOAuthProviders = computed(() => !showMagicLinkSent.value)

const toggleLinkto = computed(() => {
  if (view === 'login') {
    return {
      path: '/signup',
      query: {
        ...(loginFormData.value.email && { email: loginFormData.value.email }),
      },
    }
  } else {
    return {
      path: '/login',
      query: {
        ...(signupFormData.value.email && {
          email: signupFormData.value.email,
        }),
      },
    }
  }
})

const toggleViewText = computed(() => {
  if (view === 'login') {
    return "Don't have an account? Sign up for free"
  } else if (view === 'signup') {
    return 'Have an account? Login now'
  }
})

const showSignupCta = computed(() => view !== 'connect' && isWebContext.value)

onMounted(async () => {
  if (magicToken) {
    onMagicLinkLogin(magicToken as string)
  }
  if (route.query.error && route.query.error !== 'undefined') {
    await nextTick()
    switch (route.query.error) {
      case 'provider_conflict':
        toast(
          `This account uses a different method to login than you've selected. We've emailed you a magic link to sign in.`,
        )
        break

      default:
        toast.error('There was an error authenticating you')
        break
    }
  }
})

const onCredentialLogin = wrapWithPending(
  async (formData: any, node: FormKitNode) => {
    try {
      node.setErrors([])
      const { error } = await signIn('credentials', {
        callbackUrl: afterAuthRedirectTo.value,
        redirect: false,
        type: 'login',
        data: JSON.stringify(formData),
      })

      if (error) {
        switch (error as AuthErrorCode) {
          case 'AccessDenied':
            node.setErrors(['Wrong email or password'])
            break
          default:
            node.setErrors(['There was an error signing in'])
            break
        }
      } else {
        await navigateTo(afterAuthRedirectTo.value, { replace: true })
      }
    } catch (e) {
      toast.error('There was an error signing in')
    }
  },
)

const onCredentialSignup = wrapWithPending(
  async (formData: any, node: FormKitNode) => {
    try {
      node.setErrors([])
      let captcha = null
      try {
        captcha = await getRecaptchaToken()
      } catch (e) {}
      const { error } = await signIn('credentials', {
        callbackUrl: afterAuthRedirectTo.value,
        redirect: false,
        type: 'signup',
        data: JSON.stringify({
          captcha,
          ...formData,
          ...(claimToken ? { claimToken } : {}),
          ...(inviteToken ? { inviteToken } : {}),
        }),
      })

      if (error) {
        const errorResponse = JSON.parse(error)
        if (!errorResponse) throw new Error('no error data')
        handleFormError({ data: errorResponse }, node)
      } else {
        await navigateTo(afterAuthRedirectTo.value, { replace: true })
      }
    } catch (e) {
      toast.error('There was an error signing up')
    }
  },
)

function onForgotPassword() {
  navigateTo('/forgot-password')
}

async function onMagicLinkLogin(magicToken: string) {
  pending.value = true
  try {
    await signIn('credentials', {
      callbackUrl: afterAuthRedirectTo.value,
      redirect: false,
      type: 'magicToken',
      data: JSON.stringify({
        magicToken,
        ...(initializedEmail ? { email: initializedEmail } : {}),
      }),
    })
    await navigateTo(afterAuthRedirectTo.value, { replace: true })
  } catch (e) {
    toast.error('There was an error signing in')
  }
  pending.value = false
}

const { onSubmit: onMagicLinkRequest, pending: magicLinkPending } =
  useFormSubmit<typeof magicLinkFormData.value>(async (data) => {
    await $post(getApiPath('magicLink'), { ...data })
    magicLinkSent.value = true
  })

async function onOAuthLogin(provider: string) {
  oAuthProvidersPending.value[provider] = true
  // signin redirects to OAuth provider but might as well catch any possible error
  try {
    await signIn(provider, {
      callbackUrl: afterAuthRedirectTo.value,
    })
  } catch (e) {
    toast.error('There was an error signing in')
  }
  oAuthProvidersPending.value[provider] = false
}
</script>
